<template>
  <base-section
    id="affiliates"
    class="pb-5"
  >
    <v-container>
        <v-col
         class="overflow-x-auto"
        >
          <v-row>
            <v-col
              cols="6"
            >
              <base-heading
                title="MEMBERS IN ASSOCIATION"
                mobileSize="text-h6"
                align="center"
                size="text-h4"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <base-heading
              title="COMPANY REGISTRATION"
              mobileSize="text-h6"
              align="center"
              size="text-h4"
            />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliate-1.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliateTwo-1.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliate-2.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliateTwo-2.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliate-3.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
            <v-col>
              <base-img
                :src="require(`@/assets/affiliateTwo-3.png`)"
                color="grey"
                contain
                height="90"
                width="120"
                class="mx-auto"
              />
            </v-col>
          </v-row>
        </v-col>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
  }
</script>
